<template>
	<footer id="footer-unsubscribe">

        <div class="details__address">{{cancellation.workTime}}</div>
        <div class="details">
        <span class="details__phone">
        <img src="../../assets/img/phone.png" alt="Phone">
        {{ cancellation.address1 }} {{ specific.phone }}
        </span>
            <span class="details__email">
        <img src="../../assets/img/email.png" alt="E-mail">
        {{ specific.email }}
        </span>
        </div>

        <ul class="footer-list">
<!--			<li><router-link :to="{name: 'home'}">{{ vocab[locale].nav.home }}</router-link></li>-->
<!--			<li><router-link :to="{name:'unsubscribe'}">{{ vocab[locale].nav.unsubscribe }}</router-link></li>-->
		</ul>
	</footer>
</template>

<script>
    import {mapState} from 'vuex';
    export default {
        name: "Footer",
        computed:{
            ...mapState([
                'vocab', 'locale', 'specific'
            ]),
            cancellation() {
                return this.vocab[this.locale].cancellation;
            },
        }
    }
</script>

<style scoped>

</style>