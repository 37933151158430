<template>

	<div>
        <unsubscribe/>
		<GetInTouch/>
		<Questions/>
	</div>

</template>

<script>
	import GetInTouch from './GetInTouch';
	import Questions from './Questions';
    import Unsubscribe from "@/components/Unsubscribe/Index";
    export default {
        name: "Main",
        components: {
            Unsubscribe,
            GetInTouch, Questions
        }
    }
</script>

<style scoped>

</style>