import Vue from 'vue'
import Vuex from 'vuex';
Vue.use(Vuex);

import en_vocab from './vocab/en';
import fr_vocab from './vocab/fr';
import it_vocab from './vocab/it';
import {specific, baseUrl} from './specific';

const store = new Vuex.Store({
    state: {
        vocab: {
            en: en_vocab,
            fr: fr_vocab,
            it: it_vocab,
        },
        locale: 'en',
        specific: specific,
        routes:{
            getintouch: baseUrl + 'getintouch',
            unsubscribe: 'https://blueomedia.com/api/unsubscribe',
        },
        selector: {
            en: {
                name: 'EN',
                img: require('../assets/img/language/england.png'),
                slag: "en",
                active: true
            },
            fr: {
                name: 'FR',
                img: require('../assets/img/language/france.png'),
                slag: "fr",
                active: false
            },
            it: {
                name: 'IT',
                img: require('../assets/img/language/italy.png'),
                slag: "it",
                active: false
            }
        },
        changeLang: false,
        showModal: false,
        showLoader: false,
        modalText: null,
        errorText: 'An unknown error occured during the request, please try again later',
    },
    getters:{
        activeLang(state){
            return Object.keys(state.selector).reduce((res,el) => {return state.selector[el].active === true?el:res},'it');
        }
    },
    mutations:{
        hideModal(state){
            state.showModal = false;
        },
        setModalText(state, text){
            state.modalText = text;
            state.showModal = true;
        },
        showLoaderHandler(state){
            state.showLoader = true;
        },
        hideLoaderHandler(state){
            state.showLoader = false;
        },
        toggleLangSelector(state){
            state.changeLang = !state.changeLang;
        },
        setLang(state, lang){
            Object.keys(state.selector).map(el => state.selector[el].active = false);
            state.selector[lang].active = true;
            state.changeLang = false;
            state.locale = lang;
        }
    }
});

export default store;