<template>

	<section id="question-section">
		<div class="container">
			<div class="row">
				<div class="offset-sm-3 col-sm-6 offset-md-0 col-md-4">
					<img class="question-section__img" src="../../assets/img/call.png" alt="">
				</div>
				<div class="offset-md-1 col-md-7">
					<h4>{{ questions.title1 }}</h4>
					<p>{{ questions.desc1 }}</p>
					<h4>{{ questions.title2 }}</h4>
					<p>{{ questions.desc2 }}</p>
				</div>
			</div>

			<div class="h2__title faq__title">{{ questions.faq }}</div>
			<div class="question-block" v-for="item in questions.faqList" :key="item.id">
				<div class="question-block__title" @click="showItemDesc(item)">{{ item.question }}</div>
				<transition name="bounce">
					<div class="question-block__answer active" v-if="item.show">{{ item.answer }}</div>
				</transition>
			</div>

			<div class="h2__title">
				{{ questions.title3 }}
				<div class="subtitle">{{ questions.subTitle3 }}</div>
			</div>
			<div class="row">
				<div class="col-md-6">
					<div class="desc__block">{{ questions.desc3 }}</div>
					<div class="desc__block">{{ questions.desc4 }}</div>
				</div>
				<div class="col-md-6">
					<div class="desc__block">{{ questions.desc5 }}</div>
					<div class="desc__block">{{ questions.desc6 }}</div>
				</div>
			</div>
		</div>
	</section>

</template>

<script>
    import {mapState} from 'vuex';
    export default {
        name: "Questions",
        computed:{
            ...mapState([
                'vocab', 'locale'
            ]),
            questions(){
                return this.vocab[this.locale].questions;
            },
        },
	    methods:{
            showItemDesc(item){
                this.questions.faqList.map(el => el.show = false);
                item.show = true;
            }
	    }
    }
</script>

<style scoped>
	.bounce-enter-active {
		animation: bounce-in .25s;
	}
	.bounce-leave-active {
		animation: bounce-in .25s reverse;
	}
	@keyframes bounce-in {
		0% {
			transform: scale(0);
		}
		100% {
			transform: scale(1);
		}
	}
</style>