<template>

    <section id="cancellation">
        <div class="container">
            <h1>{{ cancellation.title1 }}</h1>
            <div class="cancellation-form">
                <form id="form-request" @submit.prevent="submitHandler">
                    <div class="cancellation-form__email">{{ cancellation.titleForm1 }}</div>
                    <div class="cancellation-form__row">
	                    <span class="msg-required">{{ errors.first('Email') }}</span>
                        <input
								v-validate.disable="'required|email'"
                                v-model="email"
                                id="email"
                                name="Email"
                                class="cancellation-form__inp"
                                placeholder="Email">
                    </div>

                    <div class="cancellation-form__info">{{ cancellation.info1 }}</div>
                    <div class="cancellation-form__agree">
                        <div class="radio-box">
                            <label>
                                <input
                                        v-model="checked"
                                        name="unsubscribe"
                                        class="cancellation-form__checkbox"
                                        id="privacy"
                                        type="checkbox">
                                <span></span>
                                {{ cancellation.text1 }}
                            </label>
                        </div>
                    </div>
                    <button class="cancellation-form__btn" :disabled="!checked">{{ cancellation.titleForm1 }}</button>
                </form>
            </div>

        </div>

        <Modal v-if="showModal"/>
    </section>

</template>

<script>
    import {mapState, mapMutations} from 'vuex';
    import Modal from '../Modal/Index'
    /* eslint-disable */
    export default {
        name: "Unsubscribe",
        components: {
            Modal
        },
        data() {
            return {
                checked: false,
                email: null
            }
        },
        computed: {
            ...mapState([
                'vocab', 'locale', 'routes', 'showModal', 'errorText', 'specific'
            ]),
            cancellation() {
                return this.vocab[this.locale].cancellation;
            },
        },
        methods: {
            submitHandler() {
                this.$validator.validate().then(result => {
                    if (result) {
                        this.axios.post(
                            this.routes.unsubscribe,
                            {
                                host: window.location.hostname,
                                email: this.email.trim()
                            },
                            {
                                headers: {
                                    'Accept': 'application/json',
                                    'Content-Type': 'application/json',
                                },
                            }
                        )
                            .then((response) => {
                                this.email = '';
                                this.setModalText(response.data.message);
                            })
                            .catch(() => {
                                this.setModalText(this.errorText);
                            })
                    }
                });
            },
            ...mapMutations([
                'setModalText', 'errorEmailInput', 'clearErrorStatus'
            ])
        }
    }
</script>

<style scoped>
</style>